import USER_ACTIONS from './constantExports/userActions'
import moment from 'moment'
import { COLOR } from '../styles/variables'

export const DEFAULT_ERROR_MESSAGE = 'An error has occured'
export const USER_TOKEN_KEY = 'fta_user_token'
export const USER_FB_LOADED = 'fb_loaded'
export const NOTIFICATIONS_KEY = 'fta_notifications'

export const SITES = {
  DK: 'dk',
  FD: 'fd',
  Y: 'y',
}

export const SLATE_STYLES = {
  CLASSIC: 'classic',
  SHOWDOWN: 'showdown',
}

const SPAM_NOTICE = `
  <br> If the message ends up in your spam folder, be sure to mark as "Not Spam" and move to your inbox
`

export const SPORTS_CHECKLIST = {
  MLB: [
    'Daily projections for hitters and pitchers',
    'Model updates as lineups are released',
    'Advanced sabermetrics',
    'Core plays for cash & GPP',
    'Community chat with MLB experts',
    'DFS MLB Lineup Optimizer',
  ],
  MLB_DRAFT_GUIDE: [
    'Top 500 Rankings for 2020 Fantasy Baseball',
    'Ideal for 5x5 fantasy drafts',
    'Rankings update as you mark players drafted',
    'NL / AL only drafts supported',
    'View top available players by position',
    'Available to FTA+ subscribers on a weekly plan or higher',
  ],
  NBA: [
    'Generate 150+ NBA Lineups',
    'Daily projections and values',
    'LIVE projection updates',
    'Advanced NBA statistical modeling',
    'Community chat with NBA experts',
    'Core plays for cash & GPP',
    'Draftkings NBA optimizer',
    'Fanduel NBA optimizer',
    'Yahoo DFS NBA optimizer',
  ],
  NFL: [
    'Weekly DFS NFL projections',
    'Model updates regularly with news',
    'Advanced statistics and metrics',
    'Community chat with NFL experts',
    'Core plays for GPP and cash',
    'Lineup optimizer & generation',
  ],
  NFL_DRAFT_GUIDE: [
    'Customizable to your league settings',
    'Value based drafting (VBD) rankings',
    'Suggested auction values',
    'View top available players by position',
    'First down and yardage bonus projections',
    'Rankings updated daily by fantasy football analysts',
  ],
  NHL: [
    'Daily slate breakdowns',
    'Core plays and top value targets',
    'Advanced DFS NHL analytics',
    'Community chat with NHL experts',
    'Updated line combinations',
    'Goalie confirmations',
  ],
}

export const USER_NOTIFICATIONS = {
  [USER_ACTIONS.accountCreated]: 'Your account has been created',
  [USER_ACTIONS.forgotUsernameEmailSent]: `Check your email for your username. <br> ${SPAM_NOTICE}`,
  [USER_ACTIONS.emailVerificationRequired]: 'Your email requires verification.',
  [USER_ACTIONS.passwordResetRequired]: 'Password reset required to continue',
  [USER_ACTIONS.resetPasswordEmailSent]: `Check your email for a link to reset your password. <br> ${SPAM_NOTICE}`,
  [USER_ACTIONS.sendForgotUsernameEmail]:
    'Enter your email address to retrieve username.',
  [USER_ACTIONS.sendResetPasswordEmail]:
    'Confirm your email address to reset password.',
  [USER_ACTIONS.checkoutComplete]:
    'Thanks for subscribing! Get started by joining chat.',
  [USER_ACTIONS.checkoutDraftGuideComplete]: `Thank you for your purchase.  Check your email for additional tips before draft day. <br> ${SPAM_NOTICE}`,
  [USER_ACTIONS.userRolesUpdated]: 'User roles updated.',
  [USER_ACTIONS.verificationEmailSent]: `Check your email for a link to activate your account. <br> ${SPAM_NOTICE}`,
}

export const USER_ROLES = {
  adminSubscriber: 'admin_subscriber',
  developer: 'developer',
  mlbAdmin: 'mlb_admin',
  mlbEditor: 'mlb_editor',
  nbaAdmin: 'nba_admin',
  nbaEditor: 'nba_editor',
  nflAdmin: 'nfl_admin',
  nflEditor: 'nfl_editor',
  nhlAdmin: 'nhl_admin',
  nhlEditor: 'nhl_editor',
  siteAdmin: 'site_admin',
  subscriber: 'subscriber',
}

export const SHOW_EDIT_PROJECTIONS_BUTTON = ['mlb', 'nhl']

export const SHOW_SLATE_FILTER_SITE = [SITES.DK, SITES.FD]

const now = new Date()
export const THIS_SEASON = now.getFullYear()
export const LAST_SEASON = THIS_SEASON - 1
export const TWO_SEASONS_AGO = THIS_SEASON - 2
export const THREE_SEASONS_AGO = THIS_SEASON - 3

export const DISCORD_LINK = 'https://discord.gg/ZrpRYq5fxy'
export const DISCORD_FF_LINK = 'https://discord.gg/wTbswKwA'

export const USER_PROJECTION_FIELDS = {
  CAPTAIN_EXCLUDE: 'captainExclude',
  CAPTAIN_LOCK: 'captainLock',
  CAPTAIN_MAX: 'captainMaxExposure',
  CAPTAIN_MIN: 'captainMinExposure',
  MVP_EXCLUDE: 'mvpExclude',
  MVP_LOCK: 'mvpLock',
  MVP_MAX: 'mvpMaxExposure',
  MVP_MIN: 'mvpMinExposure',
  STAR_EXCLUDE: 'starExclude',
  STAR_LOCK: 'starLock',
  STAR_MAX: 'starMaxExposure',
  STAR_MIN: 'starMinExposure',
  PRO_EXCLUDE: 'proExclude',
  PRO_LOCK: 'proLock',
  PRO_MAX: 'proMaxExposure',
  PRO_MIN: 'proMinExposure',
  CASH_RATING: 'cashRating',
  CEILING: 'ceiling',
  EXCLUDE: 'exclude',
  FLOOR: 'floor',
  FTA_RATING: 'ftaRating',
  GPP_RATING: 'gppRating',
  LOCK: 'lock',
  MAX: 'maxExposure',
  MIN: 'minExposure',
  POSITIONS: 'positions',
  PROJECTION: 'projection',
  REGULAR_EXCLUDE: 'regularExclude',
  REGULAR_LOCK: 'regularLock',
  REGULAR_MAX: 'regularMaxExposure',
  REGULAR_MIN: 'regularMinExposure',
}

export const SALARY = {
  DK_MAX: 50000,
  FD_MAX: 60000,
  Y_MAX: 200,
  FT_MAX: 100000,
}

export const FULL_LOWERCASE_SITE_NAME = {
  [SITES.DK]: 'draftkings',
  [SITES.FD]: 'fanduel',
  [SITES.Y]: 'yahoo',
}

export const FULL_SITE_NAME = {
  [SITES.DK]: 'Draftkings',
  [SITES.FD]: 'Fanduel',
  [SITES.Y]: 'Yahoo',
}

export const FULL_SPORT_NAME = {
  nba: 'basketball',
  nfl: 'football',
  mlb: 'baseball',
  nhl: 'hockey',
}

export const SITE_PLAYERS_MAX_STACK = {
  [SITES.DK.toUpperCase()]: 5,
  [SITES.FD.toUpperCase()]: 4,
  [SITES.Y.toUpperCase()]: 6,
}

export const SITE_PLAYERS_IN_LINEUP = {
  [SITES.DK.toUpperCase()]: {
    mlb_classic: 10,
    mlb_showdown: 6,
    nba_classic: 8,
    nba_showdown: 6,
    nhl_classic: 9,
    nhl_showdown: 6,
    nfl_classic: 9,
    nfl_showdown: 6,
  },
  [SITES.FD.toUpperCase()]: {
    mlb_classic: 9,
    mlb_showdown: 5,
    nba_classic: 9,
    nba_showdown: 5,
    nhl_classic: 9,
    nhl_showdown: 5,
    nfl_classic: 9,
    nfl_showdown: 5,
  },
  [SITES.Y.toUpperCase()]: {
    mlb_classic: 10,
    nba_classic: 8,
    nhl_classic: 9,
    nfl_classic: 9,
    mlb_showdown: 5,
    nba_showdown: 5,
    nhl_showdown: 5,
    nfl_showdown: 5,
  },
}

export const SITE_MENU_LABELS = ['DK', 'FD', 'Y']

export const SITE_MENU_VALUES = [SITES.DK, SITES.FD, SITES.Y]

export const DK_HEADER_INFO = {
  backgroundColor: COLOR.DK_ORANGE,
  color: COLOR.WHITE,
}

export const HEADER_INFO = {
  backgroundColor: COLOR.BLUE_DARK2,
  color: COLOR.WHITE,
}

export const NBA_POSITIONS = ['PG', 'SG', 'SF', 'PF', 'C']
export const NFL_POSITIONS = ['QB', 'RB', 'WR', 'TE', 'K', 'DST']

export const NBA_TEAMS = [
  'ATL',
  'BKN',
  'BOS',
  'CHA',
  'CHI',
  'CLE',
  'DAL',
  'DEN',
  'DET',
  'GSW',
  'HOU',
  'IND',
  'LAC',
  'LAL',
  'MEM',
  'MIA',
  'MIL',
  'MIN',
  'NOP',
  'NYK',
  'OKC',
  'ORL',
  'PHI',
  'PHX',
  'POR',
  'SAC',
  'SAS',
  'TOR',
  'UTA',
  'WAS',
]

export const SPORT: Record<string, string> = {
  MLB: 'mlb',
  NBA: 'nba',
  NHL: 'nhl',
  NFL: 'nfl',
  NFL_DRAFT_RANKINGS: 'nflDraftRankings',
}

export const HAS_OWNERSHIP = [SPORT.NBA, SPORT.MLB, SPORT.NHL, SPORT.NFL]

export const HAS_LINEUPS = [SPORT.MLB, SPORT.NBA, SPORT.NFL, SPORT.NHL]

export const SPORTS_USING_RATING = [SPORT.MLB]

// If we want products to show up in user dashboard, set them to active true
// Any changes made here need to be made in constants on the server as well

const MLB_DRAFT_GUIDE =
  'https://docs.google.com/spreadsheets/d/1XeB1-7J1PeQkZbX9mnaPlwn9-fl2w9n3rUSUF7VVKmw/edit?usp=sharing'

export const NFL_DRAFT_GUIDE_LINK =
  '/fantasy-football-draft-guide-rankings-crusher'

interface Product {
  active?: boolean
  fullPrice?: number
  link: string
  name: string
  price: number
  productId: string
}

const fullNflDraftGuidePrice = 16.99
export const PRODUCTS: Record<string, Product> = {
  MLB: {
    active: false,
    link: MLB_DRAFT_GUIDE,
    name: `${THIS_SEASON} Fantasy Baseball Cheat Sheet`,
    price: 8.99,
    productId: `MLB_GUIDE_${THIS_SEASON}`,
  },
  NFL: {
    active: false,
    link: NFL_DRAFT_GUIDE_LINK,
    name: `${THIS_SEASON} Fantasy Football Draft Guide`,
    price: fullNflDraftGuidePrice,
    productId: `NFL_GUIDE_${THIS_SEASON}`,
  },
  NFL_REDDIT: {
    active: true,
    fullPrice: fullNflDraftGuidePrice,
    link: NFL_DRAFT_GUIDE_LINK,
    name: `${THIS_SEASON} Fantasy Football Draft Guide - Reddit Discount`,
    price: 8.99,
    productId: `NFL_GUIDE_${THIS_SEASON}_REDDIT`,
  },
}

export const PRODUCT_IDS = {
  [PRODUCTS.MLB.productId]: PRODUCTS.MLB,
  [PRODUCTS.NFL.productId]: PRODUCTS.NFL,
  [PRODUCTS.NFL_REDDIT.productId]: PRODUCTS.NFL_REDDIT,
}

export const MODEL_NAMES: Record<string, string> = {
  MLB: 'A.C.E Projection Model',
  NBA: 'Fast Break Model',
  NHL: 'Enforcer Model',
  NFL: '1st & Goal Model',
}

export const UNIQUE_ID = {
  mlb: 'mlbId',
  nba: 'pbrId',
  nfl: 'nflId',
  nhl: 'nhlId',
}

export const MEMBERSHIP_BUTTONS = {
  ONE: 'Subscribe today',
  TWO: 'Membership options',
}

export const FB_MESSAGES = {
  CONNECTED: 'connected',
  LINK_APP: 'Link FTA+ with Facebook',
  LINK_FB_ACCOUNT: 'Continue with Facebook',
  RE_AUTH_FB: 'link',
}

export const PAYPAL_URLS: Record<string, string> = {
  development:
    'https://www.paypal.com/sdk/js?client-id=ASDYhLhcEJ1ogYtb9AP8MyVBB9X3DCacK0FJO4SHzyci4MNHg5n5hl-nUoWp7MUZwpMIotvDf3CcEFIN&currency=USD&disable-funding=credit&vault=true',
  production:
    'https://www.paypal.com/sdk/js?client-id=AVi1f58RHvkul7MhbEVceA3eucR_w_2xX3kKD9D5GiTykTW9alCs5PApX2yude8BAzXedhCJdQjjCrV8&currency=USD&disable-funding=credit&vault=true',
}

export const ELIGIBLE_POSITIONS = {
  [SPORT.NHL]: {
    C: true,
    W: true,
    D: false,
    G: false,
  },
}

export const COOKIES = {
  accept: 'fta-accept-cookies',
  mlbDraftGuide: `fta-mlb-rankings-${THIS_SEASON}`,
  nflDraftGuide: `fta-nfl-rankings-${THIS_SEASON}`,
  exit: 'fta-exit-modal-seen',
  exitSub: 'fta-exit-subscriber',
}

export const FD_SHOWDOWN = {
  sportsWithCpt: ['nhl'],
  sportsWithMvp: ['nba', 'mlb', 'nfl'],
  sportsWithStar: ['nba', 'mlb'],
  sportsWithPro: ['nba'],
}

export const NO_OPTIMIZER_TEAMS = []

export const NFL_TEAMS = [
  'ARI',
  'ATL',
  'BAL',
  'BUF',
  'CAR',
  'CHI',
  'CIN',
  'CLE',
  'DAL',
  'DEN',
  'DET',
  'GB',
  'HOU',
  'IND',
  'JAX',
  'KC',
  'LAC',
  'LAR',
  'LVR',
  'MIA',
  'MIN',
  'NE',
  'NO',
  'NYG',
  'NYJ',
  'PHI',
  'PIT',
  'SEA',
  'SF',
  'TB',
  'TEN',
  'WAS',
]

export const DRAFT_GUIDE_COLORS = {
  PASSING: COLOR.LIGHT_RED,
  RECEIVING: COLOR.GREEN_LIGHT,
  RUSHING: COLOR.BLUE_LIGHT_SKY,
  TIGHT_END: COLOR.YELLOW_DARK,
  KICKER: COLOR.DK_ORANGE,
  DST: COLOR.PURPLE,
}

export const NFL_POS_COLOR_MAP: Record<string, string> = {
  QB: DRAFT_GUIDE_COLORS.PASSING,
  WR: DRAFT_GUIDE_COLORS.RECEIVING,
  RB: DRAFT_GUIDE_COLORS.RUSHING,
  TE: DRAFT_GUIDE_COLORS.TIGHT_END,
  K: DRAFT_GUIDE_COLORS.KICKER,
  DST: DRAFT_GUIDE_COLORS.DST,
}

export const HAS_GAMES = [SPORT.NBA, SPORT.NHL, SPORT.MLB, SPORT.NFL]

export const YOUTUBE_FF_OVERVIEW = 'https://www.youtube.com/embed/fcHGlr1YZco'

export const SHOW_POSITIONS = [SPORT.NFL, SPORT.MLB, SPORT.NBA, SPORT.NHL]

// These are used if a user uploads their own CSV with team names
// And we can convert them via API
export const CONVERT_TEAMS = [SPORT.NFL, SPORT.MLB, SPORT.NHL, SPORT.NBA]

export const SHOW_TEAMS = [SPORT.NFL, SPORT.MLB, SPORT.NBA, SPORT.NHL]

export const SPORT_POSITIONS: Record<string, Record<string, boolean>> = {
  mlb: {
    P: true,
    C: true,
    '1B': true,
    '2B': true,
    '3B': true,
    SS: true,
    OF: true,
  },
  mlb_classic: {
    P: true,
    C: true,
    '1B': true,
    '2B': true,
    '3B': true,
    SS: true,
    OF: true,
  },
  mlb_showdown: {
    P: true,
    C: true,
    '1B': true,
    '2B': true,
    '3B': true,
    SS: true,
    OF: true,
  },
  nfl_classic: { QB: true, RB: true, WR: true, TE: true, D: true },
  nfl_showdown: { QB: true, RB: true, WR: true, TE: true, D: true, K: true },
  nba_classic: { PG: true, SG: true, SF: true, PF: true, C: true },
  nba_showdown: { PG: true, SG: true, SF: true, PF: true, C: true },
  nhl_classic: { G: true, W: true, D: true },
  nhl_showdown: { G: true, W: true, D: true },
}

export const HAS_MULTI_POS = [SPORT.MLB, SPORT.NBA]

export const HIDE_POSITIONS = []

export const INVERTED_COLUMNS = [
  'batting_order',
  'line',
  'pp_line',
  'projected_woba',
  'projected_xfip',
]

export enum VARIANTS {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  STANDARD = 'standard',
  TEXT = 'text',
}

export const BUTTON_STATES = {
  ERROR: 'error',
  SUCCESS: 'success',
}

export enum MUI_SIZES {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export const IS_MOBILE = (breakpoint: number) => window.innerWidth < breakpoint

export const ONLY_LETTERS_AND_NUMBERS = /^[a-z\d' ]+$/i

export const IS_TEST_ENV = process.env.NODE_ENV === 'test'
export const IS_LOCAL_DEV =
  process.env.NODE_ENV === 'development' || IS_TEST_ENV

export const ROUTES = {
  ACTIVE_SPORTS: '/api/admin/get-active-sports',
  AUTH: '/api/authenticate-user',
  CHANGES: '/api/fta/changes',
  FF_TEAM_NAMES: '/api/ff-team-names',
  GET_UPVOTES: '/api/admin/get-upvotes',
  MLB_IRT: '/api/mlb-irt',
  MLB_PROBABLE_PITCHERS: '/api/mlb-probable-pitchers',
  OWNERSHIP: '/api/user/get-ownership',
  PROJECTIONS: '/api/fta/projections',
  PURCHASE: '/api/purchase-all',
  RETRIEVE_FILTERS: '/api/optimizer/retrieve-filters',
  SOCIAL: '/api/check-social',
  SPORT_PLAYERS: '/api/get-sport-players',
  UPVOTE: '/api/upvote',
  USER_COUNT: '/api/admin/user-count',
}

export const NFL_DRAFT_GUIDE_ROUTES = {
  DELETE_DRAFT: '/api/nfl-draft-guide/delete-draft',
  DELETE_USER_DRAFT_FAVORITE: '/api/nfl-draft-guide/delete-user-draft-favorite',
  DELETE_USER_DRAFT_PICK: '/api/nfl-draft-guide/delete-user-draft-pick',
  DELETE_USER_DRAFT_PROJECTION:
    '/api/nfl-draft-guide/delete-user-draft-projection',
  GET_ADMIN_FTA_RANKINGS: '/api/nfl-draft-guide/admin/get-fta-rankings',
  GET_ADMIN_USER_DRAFTS: '/api/nfl-draft-guide/admin/get-user-drafts',
  GET_ADMIN_NEED_UPDATES: '/api/nfl-draft-guide/admin/need-updates',
  GET_USER_ADMIN_DRAFT_CHANGE_LOG: '/api/nfl-draft-guide/get-change-log',
  GET_USER_DRAFT_CHANGES: '/api/nfl-draft-guide/get-changes',
  GET_USER_DRAFT_CSV_RANKINGS: '/api/nfl-draft-guide/get-csv-rankings',
  GET_USER_DRAFT_DEFAULT_SETTINGS: '/api/nfl-draft-guide/get-default-settings',
  GET_PLAYER_POSITION_HASH: '/api/nfl-draft-guide/get-player-position-hash',
  GET_USER_DRAFT_FAVORITES: '/api/nfl-draft-guide/get-user-draft-favorites',
  GET_USER_DRAFT_PICKS: '/api/nfl-draft-guide/get-user-draft-picks',
  GET_USER_DRAFT_RANKINGS: '/api/nfl-draft-guide/get-user-draft-rankings',
  GET_USER_DRAFT_SETTINGS: '/api/nfl-draft-guide/get-user-draft-settings',
  GET_USER_DRAFT_TEAM_NAMES: '/api/nfl-draft-guide/get-team-names',
  GET_USER_DRAFTS: '/api/nfl-draft-guide/get-user-drafts',
  POST_DRAFT_RANKINGS: '/api/nfl-draft-guide/post-draft-rankings',
  POST_DRAFT_SETTINGS: '/api/nfl-draft-guide/post-draft-settings',
  POST_TEAM_NAMES: '/api/nfl-draft-guide/post-team-names',
  POST_USER_DRAFT_COMPLETE: '/api/nfl-draft-guide/post-user-draft-complete',
  POST_USER_DRAFT_FAVORITE: '/api/nfl-draft-guide/post-user-draft-favorite',
  POST_USER_DRAFT_PROJECTION: '/api/nfl-draft-guide/post-user-draft-projection',
  POST_ADMIN_CREATE_TEMP_PLAYER:
    '/api/admin/nfl-draft-guide/create-temp-player',
  PUT_TEAM_NAMES: '/api/nfl-draft-guide/put-team-names',
  PUT_USER_DRAFT_PICK: '/api/nfl-draft-guide/put-user-draft-pick',
  PUT_USER_DRAFT_RESET: '/api/nfl-draft-guide/put-draft-reset',
  PUT_USER_DRAFT_SETTINGS: '/api/nfl-draft-guide/put-draft-settings',
  PUT_USER_DRAFT_TRADE: '/api/nfl-draft-guide/put-user-draft-trade',
  PUT_ADMIN_PLAYER_UPDATE: '/api/admin/nfl-draft-guide/update-player',
}

export const INJURY_DESIGNATIONS = ['O', 'GTD', 'Q', '']
export const JOIN_FTA = 'Join FTA+'
export const SIGN_UP = 'Sign up'
export const LOG_IN = 'Log In'
export const THREADS_LINK = 'https://www.threads.net/@fanteamadvice'
export const MOUSE_OVER_POPOVER = 'mouse-over-popover'
export const MAX_FREE_MOCKS = 3
export const RESPONSIVE_IMAGE_STYLE = { width: '100%', height: 'auto' }
export const NO_DATA = '--'
export const TODAY_PRETTY_DATE = moment().format('MMMM DD, YYYY')
