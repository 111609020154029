import { BREAKPOINT, COLOR } from '../../../styles/variables'
import { css } from 'styled-components'

export const containerStyles = css`
  background-color: ${COLOR.WHITE};
  color: ${COLOR.GREY_DARK};
  min-height: 70vh;
  padding: 20px;

  div {
    line-height: 20px;
  }

  .table-container {
    background-color: black;
    box-shadow: 0 0 10px ${COLOR.GREY_DARK};
    display: block;
    margin-top: 10px;
    min-height: 70vh;
    max-height: 70vh;
    max-width: 100%;
    overflow: auto;

    table {
      border-spacing: 0;
      max-width: 100%;
      width: 100%;

      th {
        background-color: ${COLOR.BLUE_DARK};
        color: ${COLOR.WHITE};
        font-size: 16px;
        font-weight: 400;
        padding: 10px 6px;
        position: sticky;
        top: 0;
        z-index: 2;

        &:first-of-type {
          color: ${COLOR.WHITE};
          position: sticky;
          left: 0;
          top: 0;
        }

        &.grey {
          background-color: ${COLOR.GREY_LIGHT};
        }
      }

      td {
        min-width: 100px;
        outline-offset: -3px;
        text-align: center;

        &:first-of-type {
          position: sticky;
          left: 1px;
        }

        img {
          margin-right: 10px;
        }
      }
    }

    h2 {
      text-align: center;
      width: 100%;
    }

    .sortable {
      cursor: pointer;
    }
  }

  .desktop {
    display: inline-block;
  }

  .mobile {
    display: none;
  }

  .memberships-link {
    margin: 10px 0;
    text-align: center;
    width: 100%;
  }

  .updated-at {
    font-weight: 300;
    margin: 10px 0;
  }

  .dvp-explanation {
    font-size: 1.1em;
    font-weight: 300;
    line-height: 20px;
    margin: 20px 0;
    padding: 0 10px;

    &.italics {
      font-style: italic;
    }
  }

  tr:nth-child(odd) {
    background-color: ${COLOR.WHITE};
    color: ${COLOR.GREY_DARK};
  }

  tr:nth-child(even) {
    background-color: ${COLOR.GREY_LIGHT2};
    color: ${COLOR.GREY_DARK};
  }

  @media screen and (max-width: ${BREAKPOINT.MEDIUM}) {
    .table-container {
      font-size: 0.85em;
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: inline-block;
    }
  }
`
