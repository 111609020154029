// @ts-nocheck
import Cookies from 'js-cookie'
import axios from 'axios'
import getUrlParam from './helperFunctions/getUrlParam'
import {
  COOKIES,
  NOTIFICATIONS_KEY,
  ROUTES,
  USER_FB_LOADED,
  USER_TOKEN_KEY,
} from './constants'
import { notifyFailure, notifySuccess, parseResponse } from './helpers'

/* global FB */
/* global fbLoaded */

const defer = () => {
  const bag = {}
  return Object.assign(
    new Promise((resolve, reject) => Object.assign(bag, { resolve, reject })),
    bag
  )
}

const initFacebookSdk = () => {
  window.fbLoaded = defer()
  window.fbAsyncInit = async () => {
    FB.init({
      appId: '2491042311190780',
      cookie: true,
      xfbml: true,
      version: 'v19.0',
    })

    fbLoaded.resolve()
  }
  ;(function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0]
    if (!fjs || d.getElementById(id)) {
      return
    }
    const js = d.createElement(s)
    js.id = id
    js.src = 'https://connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')
}

const handleError = (error, returnString) => {
  const errorResponse =
    error?.response?.data?.error ||
    'Something went wrong. Please try again or contact us via Discord (link provided in the footer)'

  notifyFailure(errorResponse)
  if (returnString) return errorResponse
  return { errorResponse }
}

export const retrieveLocalUserData = () => {
  const userLocalDataString = localStorage.getItem(USER_TOKEN_KEY)

  if (!userLocalDataString) return false

  try {
    return JSON.parse(userLocalDataString)
  } catch {
    return false
  }
}

export const setLocalUserData = async (userData) => {
  const userDataString = JSON.stringify(userData)

  localStorage.removeItem(USER_TOKEN_KEY)
  localStorage.setItem(USER_TOKEN_KEY, userDataString)
  return null
}

export const submitSetUserPreferences = async (
  preferences: any,
  notify?: boolean
) => {
  const userLocalData = retrieveLocalUserData()

  if (
    !userLocalData ||
    !userLocalData.id ||
    !userLocalData.token ||
    !userLocalData.username
  )
    return false

  const request = {
    preferences,
    userId: userLocalData.id,
    token: userLocalData.token,
  }

  if (preferences && preferences.notifications) {
    localStorage.setItem(NOTIFICATIONS_KEY, true)
  }
  return axios
    .post('/api/set-preferences', request)
    .then((res) => res)
    .then(() => {
      if (notify) notifySuccess('Updated user preferences')
    })
    .catch(handleError)
}

export const submitSignup = (form) =>
  axios
    .post('/api/sign-up', form)
    .then((signupResponse) => ({ errorResponse: !signupResponse?.data?.user }))
    .catch((e: unknown) => ({ errorResponse: handleError(e, true) }))

export const submitFacebookSignup = (form) =>
  axios
    .post('/api/facebook-sign-up', form)
    .then(async (signupResponse) => {
      const userResponse = signupResponse.data.user

      const localData = {
        id: userResponse.id,
        username: userResponse.username,
        token: userResponse.token,
      }

      await setLocalUserData(localData)

      return { errorResponse: null }
    })
    .catch((e: unknown) => ({
      errorResponse: handleError(e, true),
    }))

export const submitLogin = (form) =>
  axios
    .post('/api/log-in', form)
    .then((loginResponse) => {
      const user = loginResponse.data.user

      const localToken = {
        id: user.id,
        username: user.username,
        token: user.token,
      }

      const userRoles = user.roles

      setLocalUserData(localToken)

      return {
        isSub: checkIsSubscriber(userRoles),
        userResponse: user,
        errorResponse: null,
      }
    })
    .catch((e: unknown) => ({
      isSub: false,
      userResponse: null,
      errorResponse: handleError(e, true),
    }))

export const checkIsSubscriber = (userRoles) =>
  (userRoles && userRoles.subscriber) ||
  (userRoles && userRoles.admin_subscriber) ||
  (userRoles && userRoles.site_admin)

export const submitLogout = () => {
  const userLocalData = retrieveLocalUserData()

  if (!userLocalData || !userLocalData.id) return false

  localStorage.removeItem(USER_TOKEN_KEY)

  return axios
    .post('/api/log-out', { userId: userLocalData.id })
    .then((logoutResponse) => {
      const logoutData = logoutResponse.data.user

      return logoutData
    })
    .catch(handleError)
}

export const submitSendVerificationEmail = (emailAddress) =>
  axios
    .post('/api/send-verification-email', {
      emailAddress,
      redirect: window.location.pathname,
    })
    .then((sendVerificationEmailResponse) => ({
      errorResponse: sendVerificationEmailResponse.data.success !== true,
    }))
    .catch((e: unknown) => ({ errorResponse: handleError(e, true) }))

export const submitSendForgotUsernameEmail = (form) =>
  axios
    .post('/api/forgot-username', form)
    .then((sendForgotUsernameEmailResponse) => ({
      sendForgotUsernameEmailResponse,
    }))
    .catch(handleError)

export const submitSendResetPasswordEmail = (form) =>
  axios
    .post('/api/send-password-reset-email', form)
    .then(() => ({ errorResponse: null }))
    .catch((e: unknown) => ({ errorResponse: handleError(e, true) }))

export const submitPaypalCheckout = ({ subscriptionId, plan }) => {
  const userLocalData = retrieveLocalUserData()

  if (
    !userLocalData ||
    !userLocalData.id ||
    !userLocalData.token ||
    !subscriptionId ||
    !plan
  )
    return { errorResponse: handleError('Unable to process', true) }

  return axios
    .post('/api/paypal-checkout-complete', {
      subscriptionId,
      userId: userLocalData.id,
      token: userLocalData.token,
      plan,
    })
    .then((paypalCheckoutCompleteResponse) => {
      const success = paypalCheckoutCompleteResponse.data.success === true

      return { errorResponse: !success }
    })
    .catch((e: unknown) => ({ errorResponse: handleError(e, true) }))
}

export const submitUpdateUserRoles = (updatingUsername, role, roleValue) => {
  const userLocalData = retrieveLocalUserData()

  if (
    !userLocalData ||
    !userLocalData.id ||
    !userLocalData.token ||
    !userLocalData.username ||
    userLocalData.requiresActivation
  ) {
    return Promise.resolve({ isLoggedIn: false, user: null })
  }
  return axios
    .post('/api/admin/update-user-roles', {
      token: userLocalData.token,
      userId: userLocalData.id,
      updatingUsername,
      role,
      roleValue,
    })
    .then((updateUserRolesResponse) => {
      const success = updateUserRolesResponse.data.success === true

      return { updateUserRolesResponse: success }
    })
}

export const submitStripeCheckout = async ({ token, plan, discountCode }) => {
  const userLocalData = retrieveLocalUserData()

  if (
    !userLocalData ||
    !userLocalData.id ||
    !userLocalData.token ||
    !token ||
    !plan
  )
    return { errorResponse: handleError('Unable to process', true) }

  const requestObject = {
    plan,
    stripeToken: token,
    token: userLocalData.token,
    userId: userLocalData.id,
  }

  if (discountCode) requestObject.discountCode = discountCode

  return axios
    .post('/api/stripe-checkout', requestObject)
    .then((stripeCheckoutResponse) => {
      const success = stripeCheckoutResponse.data.success === true

      return { errorResponse: !success }
    })
    .catch((e: unknown) => ({ errorResponse: handleError(e, true) }))
}

export const submitStripeDraftGuideCheckout = async (data) => {
  const userLocalData = retrieveLocalUserData()
  const { token, productId, pricing } = data
  if (
    !userLocalData ||
    !userLocalData.id ||
    !userLocalData.token ||
    !token ||
    !productId ||
    !pricing
  )
    return handleError()

  const requestObject = {
    productId,
    stripeToken: token,
    token: userLocalData.token,
    userId: userLocalData.id,
    pricing,
  }

  return axios
    .post('/api/stripe-draft-guide-checkout', requestObject)
    .then((stripeDraftGuideCheckoutResponse) => {
      const success = stripeDraftGuideCheckoutResponse.data.success === true

      return { errorResponse: !success }
    })
    .catch((e: unknown) => ({ errorResponse: handleError(e, true) }))
}

export const submitPaypalDraftGuideCheckout = async ({
  orderID,
  productId,
  pricing,
}) => {
  const userLocalData = retrieveLocalUserData()

  if (
    !userLocalData ||
    !userLocalData.id ||
    !userLocalData.token ||
    !orderID ||
    !productId ||
    !pricing
  )
    return { errorResponse: handleError('Unable to process', true) }

  const requestObject = {
    orderID,
    productId,
    token: userLocalData.token,
    userId: userLocalData.id,
    pricing,
  }

  return axios
    .post('/api/paypal-draft-guide-checkout', requestObject)
    .then((paypalDraftGuideCheckoutCompleteResponse) => {
      const success =
        paypalDraftGuideCheckoutCompleteResponse.data.success === true

      return { errorResponse: !success }
    })
    .catch((e: unknown) => ({ errorResponse: handleError(e, true) }))
}

export const submitUnsubscribe = () => {
  const userLocalData = retrieveLocalUserData()

  if (!userLocalData || !userLocalData.id || !userLocalData.token) return false

  const request = {
    token: userLocalData.token,
    userId: userLocalData.id,
  }

  return axios
    .post('/api/unsubscribe', request)
    .then((unsubscribeResponse) => {
      const success = unsubscribeResponse.data.success === true

      if (success) notifySuccess('Successfully unsubscribed')
      return { errorResponse: !success }
    })
    .catch((e: unknown) => ({ errorResponse: handleError(e, true) }))
}

export const submitAuthenticateUser = async () => {
  const userLocalData = retrieveLocalUserData()

  const defaultUserData = {
    isLoggedIn: false,
    isSubscriber: false,
    user: null,
  }

  if (
    !userLocalData ||
    !userLocalData.id ||
    !userLocalData.token ||
    !userLocalData.username ||
    userLocalData.requiresActivation
  ) {
    return defaultUserData
  }

  return axios
    .post(ROUTES.AUTH, userLocalData)
    .then(async (authenticateUserResponse) => {
      const authenticateUserData = authenticateUserResponse.data.user

      if (
        !authenticateUserData ||
        authenticateUserData.id !== userLocalData.id ||
        authenticateUserData.token !== userLocalData.token ||
        authenticateUserData.username !== userLocalData.username ||
        !authenticateUserData.roles
      ) {
        localStorage.removeItem(USER_TOKEN_KEY)
        return defaultUserData
      }

      return {
        isLoggedIn: true,
        isSubscriber: !!(
          authenticateUserData.roles.subscriber ||
          authenticateUserData.roles.admin_subscriber ||
          authenticateUserData.roles.site_admin
        ),
        user: authenticateUserData,
      }
    })
    .catch((e: unknown) => {
      handleError(e)
      return defaultUserData
    })
}

export const checkForCookies = async (initialLoad) => {
  if (initialLoad) this.checkForCookieAcceptance()
  this.checkForReferral()
}

export const checkForCookieAcceptance = () => {
  const accepted = Cookies.get(COOKIES.accept)

  if (accepted) return null

  return this.setState({ acceptedCookies: false })
}

export const checkForReferral = async (user) => {
  const referralUrlParam = getUrlParam('rfr')
  if (referralUrlParam)
    Cookies.set(COOKIES.referral, referralUrlParam, { 'max-age': 864000 }) // 10 Days

  const referralCode = Cookies.get(COOKIES.referral)
  if (!referralCode || !user) return null

  const { id, roles, subscriber, isSubscriber } = user
  if (!id || !roles) return null

  const isAdmin =
    roles.admin_subscriber ||
    roles.developer ||
    roles.mlb_admin ||
    roles.nba_admin ||
    roles.nfl_admin ||
    roles.nhl_admin ||
    roles.site_admin

  // Current subscribers and admins cant be referred
  if (isAdmin || subscriber || isSubscriber) {
    if (referralUrlParam)
      Cookies.set(COOKIES.referral, referralUrlParam, { 'max-age': -1 })
    return null
  }

  return axios
    .post('/api/check-for-referral', {
      referralCode,
      userId: id,
    })
    .then((res) => res?.data?.referralCode)
}

export const handleLoadFacebook = async () => {
  initFacebookSdk()

  fbLoaded.then(() => {
    localStorage.setItem(
      USER_FB_LOADED,
      JSON.stringify({ facebookLoaded: true })
    )
  })
}

export const requestActiveSports = async () =>
  axios
    .post(ROUTES.ACTIVE_SPORTS)
    .then((res) => parseResponse(res))
    .then((res) => {
      if (!res || !res.sports) return []
      const { sports } = res

      const sportsArray = sports
        .map((sport) => sport)
        .sort((b, a) => {
          let comparison = 0
          if (a.sport > b.sport) {
            comparison = -1
          } else if (a.sport < b.sport) {
            comparison = 1
          }
          return comparison
        })

      return sportsArray
    })
